<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-12">
            <div class="card h-100">
              <div class="card-body">

                <div class="row">
                  <div class="col-lg-12">
                    <div class="printers--actions">
                      <div class="create">
                        <div @click="showCreatePopup" class="btn btn-sm btn-success">
                          <i class="fas fa-plus"></i> {{$t('popularWords.add')}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-lg-12">
                    <table class="table table-hover" style="cursor: pointer;">
                      <thead>
                      <tr>
                        <th>config_code</th>
                        <th>value</th>
                        <th>description</th>
                        <th>type</th>
                        <th>actions</th>
                      </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in configs" :key="item.id">
                          <td @click="showEditPopup(Object.assign({}, item))">{{ item.config_code }}</td>
                          <td style="word-break: break-all;" @click="showEditPopup(Object.assign({}, item))">{{ item.config_value }}</td>
                          <td @click="showEditPopup(Object.assign({}, item))">{{ item.config_description }}</td>
                          <td @click="showEditPopup(Object.assign({}, item))">{{ item.config_type }}</td>
                          <td>
                            <div @click="showDeletePopup(item.id)" class="btn btn-sm btn-danger">
                              <i class="fas fa-times"></i>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <popup
      :closeButton="createPopup.closeButton"
      :show="createPopup.show"
      :actionButton="createPopup.actionButton"
      :actionClass="createPopup.actionClass"
      @closePopup="closeCreatePopup"
      @actionPopup="addCreateSubmit"
    >
      <div slot="header">Создать новую настройку</div>
      <div slot="body">
        <create-config
          :submitConf="submitCreate"
          @submitCreate="createConfig" 
        ></create-config>
      </div>
    </popup>


    <popup
      :closeButton="editPopup.closeButton"
      :show="editPopup.show"
      :actionButton="editPopup.actionButton"
      :actionClass="editPopup.actionClass"
      @closePopup="closeEditPopup"
      @actionPopup="addEditSubmit"
    >
      <div slot="header">Изменение настройки</div>
      <div slot="body">
        <edit-config
          :submitConf="submitEdit"
          :default="editDefault"
          @submitCreate="editConfig" 
        ></edit-config>
      </div>
    </popup>


    <popup
      :closeButton="deletePopup.closeButton"
      :show="deletePopup.show"
      :actionButton="deletePopup.actionButton"
      :actionClass="deletePopup.actionClass"
      @closePopup="closeDeletePopup"
      @actionPopup="deleteSubmit"
    >
      <div slot="header">Удалить конфиг?</div>
      <div slot="body">
        Конфиг будет удален без возможности восстановления
      </div>
    </popup>

    
    
  </Layout>
</template>


<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import axios from "axios";
import Popup from "@/components/Popup";
import CreateConfig from '../../../components/admin/configs/CreateConfig.vue';
import EditConfig from '../../../components/admin/configs/EditConfig.vue';
import CopyArrayAndObject from "@/components/libraries/CopyArrayAndObject";

const copyArrObj = new CopyArrayAndObject();

/**
 * Rbac component
 */
export default {
  components: {
    Popup,
    Layout,
    PageHeader,
    CreateConfig,
    EditConfig
  },
  data() {
    return {
      configs: [],
      createPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.add'),
        actionClass: "btn-success"
      },
      editPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.save'),
        actionClass: "btn-success"
      },
      deletePopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.confirm'),
        actionClass: "btn-success"
      },
      deletedId: 0,
      submitCreate: 0,
      submitEdit: 0,
      editDefault: {
        
      },
      title: this.$t('menuitems.admin.list.configs'),
      items: [
        {
          text: this.$t('menuitems.admin.text'),
        },
        {
          text: this.$t('menuitems.admin.list.configs'),
          active: true
        }
      ]
    };
  },
  validations: {
    
  },
  computed: {
    
  },
  methods: {
    deleteSubmit(){
      let formData = new FormData();
      formData.append('id', this.deletedId);

      axios
          .post(`/v1/configs/delete-main`, formData)
          .then(() => {
            this.closeDeletePopup();
            this.getConfigs();
            this.$store.dispatch("addNotification", {
              text: 'Конфиг успешно удален',
              time: 3,
              color: "success"
            });
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    showDeletePopup(id){
      this.deletedId = id;
      this.deletePopup.show = true;
    },
    closeDeletePopup(){
      this.deletePopup.show = false;
    },
    editConfig(data){
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key].trim());
      }
      axios
          .post(`/v1/configs/update-main`, formData)
          .then(() => {
            this.closeEditPopup();
            this.getConfigs();
            this.$store.dispatch("addNotification", {
              text: 'Конфиг успешно изменен',
              time: 3,
              color: "success"
            });
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    addEditSubmit(){
      this.submitEdit++;
    },
    closeEditPopup(){
      this.editPopup.show = false;
    },
    showEditPopup(item){
      this.editDefault = copyArrObj.copy(item);
      this.editPopup.show = true;
    },
    showCreatePopup(){
      this.createPopup.show = true;
    },
    closeCreatePopup(){
      this.createPopup.show = false;
    },
    addCreateSubmit(){
      this.submitCreate++;
    },
    createConfig(data){
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key].trim());
      }
      axios
          .post(`/v1/configs/set-main`, formData)
          .then(() => {
            this.closeCreatePopup();
            this.getConfigs();
            this.$store.dispatch("addNotification", {
              text: 'Конфиг успешно добавлен',
              time: 3,
              color: "success"
            });
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    getConfigs(){
      axios
          .get(`/v1/configs/get-main`)
          .then(resp => {
            this.configs = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    }
  },
  created() {
    this.getConfigs();
  }
};
</script>

<style scoped lang="scss">
  
</style>